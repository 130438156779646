/* eslint-disable import/prefer-default-export */

export const Colors = {
  'green-core': '#39B54A',
  'green-mid': '#009640',
  orange: '#F7941E',
  blue: '#0092B2',
  purple: '#7C51A1',
  'text-dark-grey': '#4D4D4D',
  'grey-forms': '#F3F3F3',
  'green-fun': '#008237',
  'green-haze': '#00975F',
  'green-sushi': '#65B32F',
  'green-camarone': '#005F27',
  'green-yellow': '#B8E986',
  success: '#28A745',
  info: '#007BFF',
  danger: '#DC3545',
  warning: '#FFC107',
  twitter: '#1DA1F2',
  facebook: '#3B5998',
  paypal: '#009CDE',
  primary: '#009640',
};
