/** Helper functions that help parse and transform booth information. */
/* eslint-disable import/prefer-default-export */
import { groupBy, values } from 'lodash';
import { Location } from '~/helpers/types/Location';
import {
  RawBooth, PrepollBooth, PrepollBoothDay, GroupedBooths,
} from '~/helpers/types/Booth';

/** Function that gets the distance between two sets of coordinates. */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-mixed-operators */
const getDistanceBetween = (a: Location, b: Location): number => {
  // I have no idea how this works, still...
  const radToDegrees = 0.017453292519943295;
  const cos = Math.cos;
  const algorithm = 0.5 - cos((a.lat - b.lat) * radToDegrees) / 2
    + cos(b.lat * radToDegrees) * cos(a.lat * radToDegrees)
    * (1 - cos((a.lng - b.lng) * radToDegrees)) / 2;
  return 12742 * Math.asin(Math.sqrt(algorithm));
};
/* eslint-enable prefer-destructuring */

/** Helper function that sorts an array of booths by their distance from the user's location. */
/* eslint-disable no-nested-ternary */
export const sortBoothsByDistance = (userLocation: Location, booths: RawBooth[]): RawBooth[] => booths.sort((a, b): number => {
  /* TODO: Confirm this new line works as intended.
    Added to satisfy TS, but I think we don't pass booths without location to this function. */
  if (!a.latitude || !a.longitude || !b.latitude || !b.longitude) {
    return -1;
  }
  const aDistance = getDistanceBetween({ lat: a.latitude, lng: a.longitude }, userLocation);
  const bDistance = getDistanceBetween({ lat: b.latitude, lng: b.longitude }, userLocation);
  return (aDistance > bDistance ? 1 : ((bDistance > aDistance) ? -1 : 0));
});
/* eslint-enable no-nested-ternary */

/**
 * Helper function that returns all booths which have a latitude & longitude,
 * and can therefore be shown on the map.
 */
export const filterBoothsToMappable = (booths: RawBooth[]): RawBooth[] => booths.filter(
  (b) => (b.latitude && b.longitude),
);

/**
 * Helper function that takes big unstructured array of booths as output by Rocket/melons,
 * and outputs an object of booths, grouped by electorate,
 * with all prepoll booths flattened into a single booth entry with extra 'days' field.
 */
/* TODO: Make this function name clearer */
export const groupBoothsByElectorateAndDay = (booths: RawBooth[]): GroupedBooths => {
  if (!booths) {
    return {};
  }
  const filteredBooths = filterBoothsToMappable(booths);
  const boothsByElectorate = groupBy(filteredBooths, 'electorate');
  const groupedBooths: GroupedBooths = {};
  Object.keys(boothsByElectorate).forEach((electorate) => {
    const groupedByType = groupBy(
      boothsByElectorate[electorate],
      (booth: RawBooth) => (booth.prepoll === '0' ? 'pollingDay' : 'prepoll'),
    );
    groupedBooths[electorate] = {
      pollingDay: groupedByType.pollingDay,
      prepoll: [],
    };
    if (groupedByType.prepoll) {
      const prepollBooths: { [key: string]: PrepollBooth } = {};
      groupedByType.prepoll.forEach((booth) => {
        if (!(prepollBooths[booth.name])) {
          prepollBooths[booth.name] = {
            ...booth,
            days: [],
          };
        }
        const day: PrepollBoothDay = { daysFromElection: parseInt(booth.prepoll, 10) };
        if (booth.booth_time) {
          day.times = booth.booth_time;
        }
        if (prepollBooths[booth.name].days) {
          prepollBooths[booth.name].days.push(day);
        }
      });
      groupedBooths[electorate].prepoll = values(prepollBooths);
    } else {
      groupedBooths[electorate].prepoll = [];
    }
  });
  return groupedBooths;
};
