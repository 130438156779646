/**
 * Theoretically all the states and territories.
 * In practice, only those who've had state elections with the app.
 */
/* eslint-disable import/prefer-default-export */
export const NSW = 'NSW';
export const SA = 'SA';
export const TAS = 'TAS';
export const VIC = 'VIC';
export const WA = 'WA';
export const QLD = 'QLD';
export const ACT = 'ACT';
export const NT = 'NT';

export default [
  NSW,
  SA,
  TAS,
  VIC,
  WA,
  QLD,
  ACT,
];
