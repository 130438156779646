/* global google */
import React, { Component, Fragment, ReactNode } from 'react';
import ReactGA from 'react-ga';
import { Container } from 'react-bootstrap';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl'
import bbox from '@turf/bbox';
import { featureCollection, point } from '@turf/helpers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Booths from '~/components/sections/WhereToVote/BoothMap/Booths/Booths';
import { sortBoothsByDistance } from '~/helpers/BoothHelper';
import { daysUntil } from '~/helpers/DateHelper';
import { connect } from '~/store';
import OverseasInfo from '~/components/sections/WhereToVote/BoothMap/OverseasInfo/OverseasInfo';
import { Election } from '~/helpers/types/Election';
import { Config } from '~/helpers/types/Config';
import { ElectorateWithBooths } from '~/helpers/types/Booth';
import { Location } from '~/helpers/types/Location';
import WebMercatorViewport from "@math.gl/web-mercator";

/**
 * A little icon that indicates the user's location.
 * Hidden if the electorate was manually selected.
 */
const UserMarker = () => {
  const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;
  return (
    <>
      <svg height={20} viewBox="0 0 24 24" style={{ fill: '#d00', stroke: 'none', transform: `translate(${-20 / 2}px,${-20}px)` }}>
        <path d={ICON} />
      </svg>
    </>
  );
};

/** Style for wrapper of the navigation control. */
const navStyle = {
  position: 'absolute' as 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
};

type BoothMapProps = {
  /** The election details. */
  election: Election;
  /** The booths to display on the map. */
  booths: ElectorateWithBooths;
  /** The user's location details, if available. */
  userLocation?: Location;
  /** The width of the current browser window.
   * Used to determine if map should render with Container
   * to limit maximum size, or not.
   */
  width: number;
  /** Do we think this user might be located overseas?
   * Only relevant for Federal elections.
   */
  maybeOverseas?: boolean;
  /** HTV app configuration */
  config: Config;
};

type BoothMapState = {
  /** Reference to the rendered map DOM object. */
  mapRef: any;
  /** Type of booths to display (prepoll or pollingDay). */
  boothType: string;
  /** Is it election day today? */
  isElectionDay: boolean;
  /** Viewport state, used by mapbox. */
  viewport: { height: number; width: number; latitude: number; longitude: number; zoom: number };
  /** Map settings, used by mapbox. */
  settings: { dragPan: boolean; dragRotate: boolean; scrollZoom: boolean; touchRotate: boolean; keyboard: boolean; doubleClickZoom: boolean };
};

export class BoothMap extends Component<BoothMapProps, BoothMapState> {
  constructor(props) {
    super(props);
    this.state = {
      mapRef: null,
      boothType: !this.isElectionDay(props.config.election.electionDay) ? 'prepoll' : 'pollingDay',
      isElectionDay: this.isElectionDay(props.config.election.date),
      viewport: {
        height: document.documentElement.clientHeight * 0.70,
        width: 1000,
        latitude: -32.130568,
        longitude: 115.856018,
        zoom: 12,
      },
      settings: {
        dragPan: true,
        dragRotate: true,
        scrollZoom: false,
        touchRotate: true,
        keyboard: true,
        doubleClickZoom: true,
      },
    };
    this.setMapRef = this.setMapRef.bind(this);
    this.setBoothType = this.setBoothType.bind(this);
    ReactGA.event({
      category: 'Booth Map',
      action: !this.isElectionDay(props.config.election.date) ? 'Viewed Prepoll Booths' : 'Viewed Polling Day Booths',
    });
  }

  /**
   * Function that works out what the edges of the displayed map should be,
   * based on the visible booth markers and the user location marker if shown.
   */
  setMapBounds(): void {
    console.log(this.state.viewport);
    const { mapRef, boothType, viewport } = this.state;
    const { booths, userLocation } = this.props;
    const markers = [];
    const visibleBooths = boothType === 'prepoll' ? booths.prepoll : booths.pollingDay;
    visibleBooths.forEach((booth) => {
      if (booth.latitude && booth.longitude) {
        markers.push(point([parseFloat(booth.longitude), parseFloat(booth.latitude)]));
      }
    });
    if (markers.length === 0) {
      return;
    }
    if (userLocation) {
      markers.push(point([userLocation.lng, userLocation.lat]));
    }
    const collection = featureCollection(markers);
    // If there's only one marker, make the bounds a bit bigger
    // eslint-disable-next-line prefer-const
    let [minLng, minLat, maxLng, maxLat] = bbox(collection);
    if (minLng === maxLng) {
      maxLng += 0.025;
      minLng -= 0.025;
    }
    if (minLat === maxLat) {
      maxLat += 0.025;
      minLat -= 0.025;
    }
    const vp = new WebMercatorViewport(viewport);
    const { longitude, latitude, zoom } = vp.fitBounds([[minLng, minLat], [maxLng, maxLat]], { padding: 40 });
    this.setState({
      viewport: {
        ...viewport,
        longitude,
        latitude,
        zoom,
      },
    });
  }

  /** Function that's called to capture the reference to the JS map object once initialised. */
  setMapRef(ref): void {
    this.setState({ mapRef: ref }, this.setMapBounds);
  }

  /** Function that's called to toggle between viewing prepoll and polling day booths. */
  setBoothType(event, value): void {
    const { boothType } = this.state;
    const selectedBoothType = value;
    if (selectedBoothType !== boothType) {
      ReactGA.event({
        category: 'Booth Map',
        action: selectedBoothType === 'prepoll' ? 'Viewed Prepoll Booths' : 'Viewed Polling Day Booths',
      });
      this.setState(
        {
          boothType: selectedBoothType,
        },
        this.setMapBounds,
      );
    }
  }

  /**
   * Helper function to work out if today is the election day.
   * TODO: Move this into a proper helper.
   */
  /* eslint-disable class-methods-use-this */
  isElectionDay(electionDate): boolean {
    const electionDay = new Date(electionDate);
    const today = new Date();
    return electionDay.getFullYear() === today.getFullYear() && electionDay.getMonth() === today.getMonth() && electionDay.getDate() === today.getDate();
  }

  /**
   * Helper function to ensure the entire map is wrapped in a Bootstrap container,
   * if being displayed on large-width devices (e.g. desktop).
   */
  /* eslint-enable */
  renderWithContainer(children): ReactNode {
    const { width } = this.props;
    if (isWidthUp('md', width as any)) {
      return <Container>{children}</Container>;
    }
    return children;
  }

  render(): ReactNode {
    const {
      booths, userLocation, maybeOverseas, config,
    } = this.props;
    const {
      boothType, isElectionDay, viewport, settings,
    } = this.state;
    const relevantBooths = boothType === 'prepoll' ? booths.prepoll : booths.pollingDay;
    const sortedBooths = userLocation ? sortBoothsByDistance(userLocation, relevantBooths) : relevantBooths;
    return this.renderWithContainer(
      <>
        {config.showOverseasInfo && maybeOverseas && <OverseasInfo />}
        {!isElectionDay && (
          <Tabs
            variant="fullWidth"
            style={{ flexGrow: 1 }}
            className="u-bg-green-sushi"
            value={boothType}
            onChange={this.setBoothType}
            TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
          >
            <Tab label="Early Voting" style={{ color: 'white', fontWeight: 'bold' }} value="prepoll" />
            <Tab label="Election Day" style={{ color: 'white', fontWeight: 'bold' }} value="pollingDay" />
          </Tabs>
        )}
        <ReactMapGL
          {...viewport}
          {...settings}
          onViewportChange={(vp) => this.setState({ viewport: vp })}
          ref={this.setMapRef}
          mapboxApiAccessToken={config.mapboxApiKey}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          style={{ marginBottom: '-10px' }}
          width='100%'
        >
          <Booths booths={sortedBooths} daysUntilElection={daysUntil(config.election.electionDay)} electionHasSausage={config.electionHasSausage} />
          <div className="nav" style={navStyle}>
            <NavigationControl onViewportChange={(vp) => this.setState({ viewport: vp })} />
          </div>
          {userLocation && (
            <Marker latitude={userLocation.lat} longitude={userLocation.lng}>
              <UserMarker />
            </Marker>
          )}
        </ReactMapGL>
      </>,
    );
  }
}

export default withWidth()(connect(({ maybeOverseas, config }) => ({ maybeOverseas, config }))(BoothMap));
