/* eslint-disable max-len */
import React, { useState, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Container } from 'react-bootstrap';
import withConfig from '~/helpers/providers/withConfig';

type OverseasInfoProps = {
  /** The HTV app configuration. Can include merged configuration from the election and electorate. */
  config: object;
};

/**
 * Component to show the user information about voting from overseas.
 * Only relevant in Federal elections.
 * Shows a button, which if pressed, opens a dialog.
 */
const OverseasInfo: React.SFC<OverseasInfoProps> = ({ config }) => {
  const [showDialog, toggleDialog] = useState(false);
  return (
    <>
      <div>
        <button type="button" style={{ width: '100%' }} className="btn btn-primary btn-sm" onClick={() => toggleDialog(true)}>
          Voting from overseas? Check out our guide.
        </button>
      </div>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <div className="u-bg-green-fun">
          <h1
            style={{
              color: 'white',
              width: '100%',
              padding: '15px',
              textAlign: 'center',
            }}
          >
            Overseas Voting Guide
          </h1>
        </div>
        <Container>
          <h2>Vote at an Australian Embassy, High Commission or Consulate overseas</h2>
          <p>Early voting facilities may be available in person at some Australian diplomatic missions overseas.</p>
          <p>
            Contact your nearest&nbsp;
            <a href="https://dfat.gov.au/about-us/our-locations/missions/Pages/our-embassies-and-consulates-overseas.aspx">
              Australian Embassy, High Commission or Consulate&nbsp;
            </a>
            for opening hours and further details.&nbsp;
          </p>
          <p>
            In some places, voting will be available by appointment only.&nbsp; Specific security or entry requirements may be
            in place, particularly at high risk locations.&nbsp;
          </p>
          <p>
            To ensure you receive the correct ballot papers,&nbsp; you will need to know which Australian address you are
            currently enrolled at.&nbsp; Some locations will have computer terminals available for voters,&nbsp; but this may
            not be offered in all countries.&nbsp; Confirm your enrolment information before visiting an Australian Embassy,
            High Commission or Consulate.&nbsp;
          </p>
          <h2>Unable to vote</h2>
          <p>
            If you&#39;re overseas and unable to vote,&nbsp; complete an&nbsp;
            <a href="http://www.aec.gov.au/Enrolling_to_vote/pdf/forms/overseas/overseas-notification-form.pdf">
              overseas notification form&nbsp;
            </a>
            and&nbsp;
            <a href="https://www.aec.gov.au/enrol/send-form.htm">return it to the AEC.&nbsp;</a>
            Being overseas is a valid reason for not voting.&nbsp;
          </p>
        </Container>
        <button type="button" className="btn btn-primary btn-sm" onClick={() => toggleDialog(false)} color="primary">
          Close
        </button>
      </Dialog>
    </>
  );
};

export default withConfig(OverseasInfo);
