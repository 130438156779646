import React from 'react';
import { Container } from 'react-bootstrap';
import { PopGrid } from '@australiangreens/components';
import ElectionIssues from '~/helpers/constants/ElectionIssues';

/**
 * Component for the 2022 Victorian election "Why Vote Green" section.
 */
export const Vic22WVG = () => (
  <Container>
    <div className="container pt-1">
      <p>With more Greens in Parliament we can hold Labor to account and push them to deliver on climate action and housing affordability.</p>
      <PopGrid tiles={ElectionIssues.nsw23} rowSize={3} />
      <p>&nbsp;</p>
      <p>Positive change starts with voting for people who will work for you and what you believe in. <a href="https://greens.org.au/vic/platform">Read more about our plans &gt;&gt;</a>.</p>
    </div>
  </Container>
);

export default Vic22WVG;
