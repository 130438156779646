/** In theory, all the types of electorate. */
/** A state or federal lower house electorate. */
export const DISTRICT = 'District';
/**
 * A state upper house electorate,
 * where a state has an upper house with multiple electorates (e.g. Victoria).
 */
export const REGION = 'Region';
/** A federal upper house electorate,
 * or a state upper house electorate where a state has an upper house with statewide representatives (e.g. New South Wales). */
export const STATE = 'State';

export default [
  DISTRICT,
  REGION,
  STATE,
];
