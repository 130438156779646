import React from 'react';

const backgroundModifiers = {
  full: 'page-section__heading--bg',
  left: 'page-section__heading--bg-offset-right',
  right: 'page-section__heading--bg-offset-left',
};

type PageSectionProps = {
  background?: 'full' | 'left' | 'right';
  title: string;
  intro?: string;
};

const PageSection: React.SFC<PageSectionProps> = ({
  background,
  title,
  intro,
  children,
}) => {
  const backgroundClass = background ? backgroundModifiers[background] : '';
  const headerClass = background ? 'u-c-white' : '';
  return (
    <div className="page-section">
      <div className={`page-section__heading ${backgroundClass}`}>
        <div className="page-section__heading-content">
          <div className="container">
            <h2 className={`page-section__title ${headerClass}`}>{title}</h2>
            {intro && (
              <div className={`page-section__intro ${headerClass}`}>
                <p>{intro}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="page-section__content">
        <div className="container" style={{ paddingTop: '20px' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

PageSection.defaultProps = {
  background: undefined,
  intro: '',
  children: null,
};

export default PageSection;
