import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import withConfig from '~/helpers/providers/withConfig';
import { Config } from '~/helpers/types/Config';

/* TODO: All these icons are copied in multiple places. Should consolidate. */
/** A styled Facebook icon. */
const FacebookIcon = styled.a.attrs({ className: 'fa fa-facebook fa-2x' })`
  padding: 5px;
`;
/** A styled Twitter icon. */
const TwitterIcon = styled.a.attrs({ className: 'fa fa-twitter fa-2x' })`
  padding: 5px;
`;
/** A styled WhatsApp icon. */
const WhatsappIcon = styled.a.attrs({ className: 'fa fa-whatsapp fa-2x' })`
  padding: 5px;
`;

type ShareDialogProps = {
  /** Is the dialog open and should therefore be showing? */
  open: boolean;
  /** Function to call when the user closes the dialog.
   * May be called by them clicking close icon, or clicking outside the dialog.
   */
  onClose: (...args: any[]) => any;
  /** The app configuration. May include merged config from election and electorate. */
  config: Config;
};

/**
 * Component that renders a dialog allowing the user to share the How To Vote app on
 * Facebook, Twitter, and WhatsApp.
 */
export const ShareDialog: React.SFC<ShareDialogProps> = ({ open, onClose, config }) => {
  const fbUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(config.shareUrl.toString())}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(config.shareText)}&url=${encodeURIComponent(config.shareUrl.toString())}`;
  const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(`${config.shareText} ${config.shareUrl.toString()}`)}`;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Share</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <a href={fbUrl} target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
          <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </a>
          <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
            <WhatsappIcon />
          </a>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withConfig(ShareDialog);
