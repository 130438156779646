/* eslint-disable import/prefer-default-export */

export const getLocationFromBrowser = (callback: (arg0: any) => any, handleError: (arg0: any) => any) => {
  if (!navigator.geolocation) {
    handleError({ code: 4 });
    return false;
  }

  const options = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 20000,
  };

  return navigator.geolocation.watchPosition(
    (pos) => callback(pos),
    (err) => handleError(err),
    options,
  );
};
