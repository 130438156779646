import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Books from './books-pink.png';
import BooksBrown from './books-brown.png';
import Overdevelopment from './overdevelopment-green.png';
import Wind from './wind-brown.png';
import WindPurple from './wind-purple.png';
import House from './house-blue.png';
import HouseOrange from './house-orange.png';
import Manufacturing from './manufacturing-yellow.png';
import Medical from './medical-pink.png';
import Mining from './mining-orange.png';
import BriefcaseOfCash from './briefcase-of-cash-purple.png';
import Transport from './transport-yellow.png';
import Person from './person-green.png';
import FiguresBlue from './figures-blue.png';
import FiguresPurple from './figures-purple.png';

const Style = `
<style>
.list-link:hover {
  font-size: 105%;
  color: #000;
}
.list-link {
  color: #000;
  display:flex;
  transition: 0.2s;
}


.list-link:hover .htv-icon {
  max-height: 68px;
}
.htv-icon {
  max-height: 64px;
  transition: 0.2s;
}

#htv-back-img-div { 
  overflow-x: hidden;
}

.htv-back-img-div-sm {
   max-height:75vh;
}

.htv-back-img {
  max-height:100%;
  height:100%;
  max-width:170%;
}

.splodge-icon {
  max-height: 16px;
}

.rounded {
    border-radius: 0.3rem !important;
}

@media screen and (min-width:768px) { 
  .my-md-6 {
    margin-bottom: 6rem;
  }
  .bg-md-light-grey {
      background-color: #f8f8f8;
  }
}

@media screen and (min-width:1060px) {
  .list-link:hover .htv-icon {
    max-height: 48px;
  }
  .htv-icon {
    max-height: 46px;
    transition: 0.2s;
  }
}

@media screen and (min-width:1200px) {
  .scrim {
    position: absolute;
    left: 0;
    bottom: 0;
    right: auto;
    top: 0;
    z-index: 10;
    width: 100% !important;
    background: -webkit-linear-gradient(right, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to left, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
  }
  .my-xl-6 {
    margin-bottom: 6rem;
  }
  .mb-xl-6 {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width:1400px) {
  .htv-back-img-lg {
    max-width:150%;
  }
}


@media screen and (min-width:1700px) {
  .col-xxl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .list-link:hover .htv-icon {
    max-height: 68px;
  }
  .htv-icon {
    max-height: 64px;
    transition: 0.2s;
  }
  .htv-back-img-xxl {
    max-width:100%;
  }
  .mt-xxl-6 {
    margin-top: 2rem !important;
  }
  .htv-back-img-div-xxl {
     max-height:100vh;
  }
}
</style>`;

const Container = styled.div.attrs({ className: 'col-md-12 col-12' })`
background-color: white;
`;

const Row = styled.div.attrs({ className: 'row justify-content-center' })``;
const Col = styled.div.attrs({ className: 'col-xxl-10 col-md-12 col-10' })``;

const Heading = styled.h3.attrs({ className: 'mt-3 text-secondary' })`
font-weight: 700;
`;

const List = styled.ul.attrs({ className: 'list-unstyled mt-3' })``;
const ListItem = styled.li.attrs({ className: 'media mt-3 mt-xxl-6' })``;
const ListLink = styled.a.attrs({ className: 'list-link' })`
font-weight: 300;
`;
const ListIcon = styled.img.attrs({ className: 'mr-5 img-fluid htv-icon' })``;
const ListBody = styled.div.attrs({ className: 'media-body' })`
font-weight: 300;
`;
const ListBold = styled.span`
font-weight: 500;
`;

/**
 * Generates a listem item from provided bullet points.
 */
export const generatePoints = (Points: any[]): ReactNode => (
  <>
    {Points.map((bul) => (
      <ListItem>
        <ListLink href={bul.link} target="_blank">
          <ListIcon src={bul.icon} alt={bul.iconAlt} />
          <ListBody>
            <ListBold>{bul.bold}</ListBold> {bul.text}
          </ListBody>
        </ListLink>
      </ListItem>
    ))}
  </>
);

const defaultItems = [
  {
    icon: Books,
    iconAlt: 'Books',
    bold: 'Fully fund Queensland state schools',
    text: 'with an extra $7 billion over four years for smaller class sizes, more teachers and better resources',
    link: 'https://greens.org.au/qld/schools',
  },
  {
    icon: Manufacturing,
    iconAlt: 'Manufacturing',
    bold: 'Revive Queensland manufacturing',
    text: 'by building publicly owned solar panel, wind turbine and green steel factories to create 19,500 jobs a year',
    link: 'https://greens.org.au/qld/manufacturing',
  },
  {
    icon: Overdevelopment,
    iconAlt: 'Crane',
    bold: 'End overdevelopment,',
    text: 'stop the sell-off of public land, and make developers pay their fair share to fund crucial public infrastructure',
    link: 'https://greens.org.au/qld/planningact',
  },
  {
    icon: Medical,
    iconAlt: 'Stethoscope',
    bold: 'Fully fund our public hospitals',
    text: 'with 9,500 more nurses and doctors, and build 200 public health clinics with bulk-billing doctors',
    link: 'https://greens.org.au/qld/publichealth',
  },
  {
    icon: Wind,
    iconAlt: 'Wind farm',
    bold: 'Build 100% publicly owned renewable energy by 2030,',
    text: 'creating 23,000 jobs a year',
    link: 'https://greens.org.au/qld/renewables',
  },
  {
    icon: Mining,
    iconAlt: 'Pickaxe',
    bold: 'Increase mining royalties and levy the big banks',
    text: 'to raise an extra $60 billion over four years',
    link: 'https://greens.org.au/qld/fairshare',
  },
  {
    icon: House,
    iconAlt: 'House',
    bold: 'Create 20,000 construction jobs',
    text: 'building 100,000 quality public homes over four years',
    link: 'https://greens.org.au/qld/jobsandhomes',
  },
  {
    icon: BriefcaseOfCash,
    iconAlt: 'Briefcase of cash',
    bold: 'Ban corporate donations,',
    text: 'cash-for-access meetings, and end corporate control of politics',
    link: 'https://greens.org.au/qld/stopcorruption',
  },
];

const electorateItems = {
  Miller: [
    {
      icon: Medical,
      iconAlt: 'Stethoscope',
      bold: 'Fully fund public healthcare',
      text: 'with 9,500 new nurses and doctors, 200 bulk-billed GP clinics, and free hospital parking',
      link: 'https://greens.org.au/qld/publichealth',
    },
    {
      icon: WindPurple,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy by 2030',
      text: 'and manufacturing green steel, solar panels and wind turbines in QLD',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: BooksBrown,
      iconAlt: 'Books',
      bold: 'Close the public school funding gap',
      text: 'with an extra $7 billion over four years for smaller class sizes, better resources, and no fees',
      link: 'https://greens.org.au/qld/schools',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Introduce free public transport & reduce traffic congestion',
      text: 'with new bus routes and more high-frequency buses on every major road',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Stop bad development and give residents a real say',
      text: 'on their neighbourhood plans by overhauling the Planning Act',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: FiguresBlue,
      iconAlt: 'Figures',
      bold: 'Funded by increasing mining royalties',
      text: 'and levying big banks and property developers, raising $68 billion over four years',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  Greenslopes: [
    {
      icon: WindPurple,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy by 2030',
      text: 'and manufacturing solar panels, wind turbines and green steel here in QLD',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: BooksBrown,
      iconAlt: 'Books',
      bold: 'Fully fund state schools',
      text: 'with an extra $7 billion over four years for smaller class sizes, better resources, more teachers and no school fees',
      link: 'https://greens.org.au/qld/schools',
    },
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Stop bad development',
      text: 'by overhauling the Planning Act, giving locals a real say on neighbourhood plans',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: HouseOrange,
      iconAlt: 'House',
      bold: 'Create 20,000 construction jobs',
      text: 'and guarantee everyone a home by building 100,000 QLD public homes',
      link: 'https://greens.org.au/qld/jobsandhomes',
    },
    {
      icon: Medical,
      iconAlt: 'Stethoscope',
      bold: 'Fully fund public healthcare',
      text: 'with 9,500 new nurses and doctors, 200 bulk-billed GP clinics, and free hospital parking',
      link: 'https://greens.org.au/qld/publichealth',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Introduce free public transport & reduce traffic congestion',
      text: 'with new bus routes and more high-frequency buses on every major road',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: FiguresBlue,
      iconAlt: 'Figures',
      bold: 'Funded by increasing mining royalties',
      text: 'and levying big banks and property developers, raising $68 billion over four years',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  Cooper: [
    {
      icon: Medical,
      iconAlt: 'Stethoscope',
      bold: 'Fully fund public healthcare',
      text: 'with 9,500 new nurses and doctors, 200 bulk-billed GP clinics, and free hospital parking',
      link: 'https://greens.org.au/qld/publichealth',
    },
    {
      icon: WindPurple,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy by 2030',
      text: 'and manufacturing green steel, solar panels and wind turbines in QLD',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: BooksBrown,
      iconAlt: 'Books',
      bold: 'Close the public school funding gap',
      text: 'with an extra $7 billion over four years for smaller class sizes, better resources, and no fees',
      link: 'https://greens.org.au/qld/schools',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Introduce free public transport & reduce traffic congestion',
      text: 'with new bus routes and more high-frequency buses on every major road',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Stop bad development and give residents a real say',
      text: 'on their neighbourhood plans by overhauling the Planning Act',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: FiguresBlue,
      iconAlt: 'Figures',
      bold: 'Funded by increasing mining royalties',
      text: 'and levying big banks and property developers, raising $68 billion over four years',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  Clayfield: [
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Stop bad development and give residents a real say',
      text: 'in their neighbourhood by overhauling the Planning Act',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: Wind,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy by 2030',
      text: 'and manufacturing green steel, solar panels and wind turbines in QLD',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Reduce traffic congestion',
      text: 'by expanding our bus and rail network and making public transport free',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: HouseOrange,
      iconAlt: 'House',
      bold: 'Create 20,000 construction jobs',
      text: 'and make having a home affordable for all by building 100,000 public homes',
      link: 'https://greens.org.au/qld/jobsandhomes',
    },
    {
      icon: BriefcaseOfCash,
      iconAlt: 'Briefcase of cash',
      bold: 'Ban corporate donations',
      text: 'and end cash-for-access meetings for all political parties',
      link: 'https://greens.org.au/qld/stopcorruption',
    },
    {
      icon: FiguresBlue,
      iconAlt: 'Figures',
      bold: 'Funded by increasing mining royalties',
      text: 'and levying big banks and property developers, raising $68 billion over four years',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  'South Brisbane': [
    {
      icon: Medical,
      iconAlt: 'Stethoscope',
      bold: 'Make public healthcare truly free and universal',
      text: 'with 9,500 new nurses and doctors, 200 bulk-billed GP clinics, and free hospital parking',
      link: 'https://greens.org.au/qld/publichealth',
    },
    {
      icon: Wind,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy by 2030',
      text: 'and manufacturing solar panels, wind turbines and green steel here in QLD',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: Books,
      iconAlt: 'Books',
      bold: 'Close the public school funding gap',
      text: 'with an extra $7 billion over four years for smaller class sizes, better resources, and no fees',
      link: 'https://greens.org.au/qld/schools',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Introduce free public transport',
      text: 'and expand bus routes and ferry terminals to reduce traffic congestion',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Give locals a real say on development',
      text: 'by overhauling the Planning Act and introduce minimum requirements for greenspace',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: FiguresBlue,
      iconAlt: 'Figures',
      bold: 'Funded by increasing mining royalties',
      text: 'and levying big banks and property developers, raising $68 billion over four years',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  McConnel: [
    {
      icon: House,
      iconAlt: 'House',
      bold: 'Create 20,000 construction jobs and guarantee everyone a home',
      text: 'by building 100,000 public homes',
      link: 'https://greens.org.au/qld/jobsandhomes',
    },
    {
      icon: Medical,
      iconAlt: 'Stethoscope',
      bold: 'Make public healthcare truly free and universal',
      text: 'with 9,500 new nurses and doctors, 200 bulk-billed GP clinics, and free hospital parking',
      link: 'https://greens.org.au/qld/publichealth',
    },
    {
      icon: Wind,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy',
      text: 'and manufacturing solar panels, wind turbines and green steel right here in Queensland',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Reduce traffic congestion',
      text: 'by expanding our bus and rail network and making public transport free',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Overhaul the Planning Act to give locals a real say on development',
      text: 'and protect and grow green space',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: FiguresPurple,
      iconAlt: 'Figures',
      bold: 'Funded by increasing mining royalties',
      text: 'and levying big banks and property developers, raising $68 billion over four years',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  Maiwar: [
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Overhaul the Planning Act to give locals a real say on development',
      text: 'and introduce minimum requirements for greenspace',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Reduce traffic congestion',
      text: 'by expanding our bus and rail network and making public transport free',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: Wind,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy',
      text: 'and manufacturing solar panels, wind turbines and green steel right here in Queensland',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: House,
      iconAlt: 'House',
      bold: 'Create 20,000 construction jobs and guarantee everyone a home',
      text: 'by building 100,000 public homes',
      link: 'https://greens.org.au/qld/jobsandhomes',
    },
    {
      icon: Books,
      iconAlt: 'Books',
      bold: 'Fully fund state schools',
      text: 'with an extra $7 billion over four years for smaller class sizes, more teachers and no state school fees',
      link: 'https://greens.org.au/qld/schools',
    },
    {
      icon: FiguresPurple,
      iconAlt: 'Figures',
      bold: 'Funded by a 0.05% levy on the big banks and increased coal and gas royalties',
      text: '',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
  Moggill: [
    {
      icon: WindPurple,
      iconAlt: 'Wind farm',
      bold: 'Create 42,500 jobs building 100% publicly owned renewable energy',
      text: 'and manufacturing solar panels, wind turbines and green steel right here in Queensland',
      link: 'https://greens.org.au/qld/renewables',
    },
    {
      icon: House,
      iconAlt: 'House',
      bold: 'Create 20,000 construction jobs',
      text: 'and guarantee everyone a home by building 100,000 public homes',
      link: 'https://greens.org.au/qld/jobsandhomes',
    },
    {
      icon: Transport,
      iconAlt: 'Transport',
      bold: 'Reduce traffic congestion',
      text: 'by expanding our bus and rail network and making public transport free',
      link: 'https://greens.org.au/qld/publictransport',
    },
    {
      icon: BooksBrown,
      iconAlt: 'Books',
      bold: 'Fully fund state schools',
      text: 'with an extra $7 billion over four years for smaller class sizes, more teachers and no state school fees',
      link: 'https://greens.org.au/qld/schools',
    },
    {
      icon: Person,
      iconAlt: 'Green person',
      bold: 'Overhaul the Planning Act to give locals a real say on development',
      text: 'and introduce minimum requirements for greenspace',
      link: 'https://greens.org.au/qld/planningact',
    },
    {
      icon: FiguresBlue,
      iconAlt: 'Figures',
      bold: 'Funded by a 0.05% levy on the big banks and increased coal and gas royalties',
      text: '',
      link: 'https://greens.org.au/qld/fairshare',
    },
  ],
};

/**
 * Component for the 2020 Queensland state election "Why Vote Green" section.
 */
export const Qld20sWVG = ({ electorate }) => (
  <Container>
    <div dangerouslySetInnerHTML={{ __html: Style }} />
    <Row>
      <Col>
        <Heading>We refuse donations from corporations, so we&apos;ll fight for you.</Heading>
        <List>
          {generatePoints(electorateItems[electorate] || defaultItems)}
        </List>
      </Col>
    </Row>
  </Container>
);

export default Qld20sWVG;
