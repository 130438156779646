/* eslint-disable jsx-a11y/anchor-is-valid,
jsx-a11y/click-events-have-key-events,
jsx-a11y/interactive-supports-focus */
import React from 'react';
import { Marker } from 'react-map-gl';
import { DemocracySausageStall } from '~/helpers/types/DemocracySausageStall';
import { Location } from '~/helpers/types/Location';
import BoothIcon from './BoothIcon.png';
import BoothIconWithSausage from './BoothIconWithSausage.png';

type BoothMarkerProps = {
  /** The location of the marker on the map. */
  location: Location;
  /** The function to be called if the marker is clicked/tapped. */
  onClick: (...args: any[]) => any;
  /** The Democracy Sausage stall, if found. */
  stall: DemocracySausageStall;
  /** Is this election enabled for Democracy Sausage integration? */
  electionHasSausage: boolean;
};

/**
 * Component that shows a marker on the 'Where to Vote' map representing a booth.
 * Icon is either a standard 'ballot box' icon,
 * or a sausage icon if Democracy Sausage integration is enabled, booth has been matched,
 * and the polling place entry in Democracy Sausage is indicated as having a sausage sizzle stall.
 */
const BoothMarker: React.SFC<BoothMarkerProps> = ({
  location,
  onClick,
  stall,
  electionHasSausage,
}) => {
  let icon;
  if (electionHasSausage && stall && stall.noms && stall.noms.bbq) {
    icon = BoothIconWithSausage;
  } else {
    icon = BoothIcon;
  }
  return (
    <Marker
      latitude={location.lat}
      longitude={location.lng}
      offsetLeft={-16.5}
      offsetTop={-44}
    >
      <a onClick={onClick} role="button">
        <img src={icon} alt="Booth icon" width="33" />
      </a>
    </Marker>
  );
};

export default BoothMarker;
