import React from 'react';
import withConfig from '~/helpers/providers/withConfig';
import ErrorBoundary from '~/components/wrappers/ErrorBoundary/ErrorBoundary';
import { Config } from '~/helpers/types/Config';
import withElectorates from '~/helpers/providers/withElectorates';
import { SelectedElectorates } from '~/helpers/types/Electorate';
import Qld20sWVG from './Qld20sWVG/Qld20sWVG';

type WhyVoteGreenProps = {
  /** HTV app configuration. Can contain merged config from the election and/or electorates. */
  config: Config;
  electorates: SelectedElectorates;
};

/**
 * The "Why Vote Green" section of the app.
 * Content entirely driven by config, usually set at the election level,
 * but can be overridden at the electorate level as needed.
 */
export const WhyVoteGreen: React.SFC<WhyVoteGreenProps> = ({ config, electorates }) => (
  <ErrorBoundary>{config.election && config.election.code === 'qld20s' ? <Qld20sWVG electorate={electorates?.lower?.name} /> : config.whyVoteGreenContent}</ErrorBoundary>
);

export default withElectorates(withConfig(WhyVoteGreen));
