/** Helpers for working with location guesses */
/* eslint-disable import/prefer-default-export */

import { sortBy } from 'lodash';
import LocationSources from '~/helpers/constants/LocationSources';

/** Helper function that sorts guesses by source and then by accuracy */
export const sortGuesses = (guesses) => (
  sortBy(guesses, [
    (g) => (g.source === LocationSources.ADDRESS ? -g.timestamp : 1),
    'accuracy',
  ])
);
