/** Helpers for working with dates. */
/* eslint-disable import/prefer-default-export */

/** Helper function that calculates the number of days between the current date and a specified date. */
export const daysUntil = (date: string | Date): number => {
  const dateObj = new Date(date);
  dateObj.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const res = Math.abs(dateObj.valueOf() - today.valueOf()) / 1000;
  const days = Math.floor(res / 86400);
  return days;
};
