import React from 'react';
import Logo from './greenslogo.png';
import BWLogo from './bwlogo.png';

type GreensLogoProps = {
  width?: string;
  height?: string;
  bw?: boolean;
};

const GreensLogo: React.SFC<GreensLogoProps> = ({ width, height, bw }) => (
  <img alt="Greens logo" src={bw ? BWLogo : Logo} style={{ width, height }} />
);

GreensLogo.defaultProps = {
  width: '100%',
  height: '100%',
  bw: false,
};

export default GreensLogo;
