import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../helpers/Variables';

const Glass = styled.div`
  border: 3px solid ${Colors['green-core']};
  border-radius: 100px;
  height: 450px;
  width: 80px;
  margin: auto;
  position: relative;
  box-sizing: content-box !important;
  line-height: 24px !important;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    background: linear-gradient(to bottom, ${Colors['green-core']} 0%, ${Colors['green-core']} 20%, rgba(255, 255, 255, 0) 21%, rgba(255, 255, 255, 0) 100%);
    background-size: 100% 13px;
  }
`;

const Liquid = styled.div`
  background: ${Colors['green-mid']};
  border-radius: 0px 0px 100px 100px;
  width: 100%;
  height: ${(props) => `${props.percent}%`};
  position: absolute;
  bottom: 0px;
  box-sizing: content-box !important;
  line-height: 24px !important;
  transition: height 2s;
`;

type ThermometerProps = {
  percent: number;
};

const Thermometer: React.SFC<ThermometerProps> = ({ percent }) => (
  <Glass>
    <Liquid percent={percent} />
  </Glass>
);

export default Thermometer;
