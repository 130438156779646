import React from 'react';
import { Provider } from '~/store';

/**
 * Wrapper that adds the store provider, allowing children to access
 * state and actions from the store.
 */
const StoreWrapper: React.SFC<{}> = ({ children }) => (
  <Provider>{children}</Provider>
);

export default StoreWrapper;
