/* global google */
import React, { Component, ReactElement } from 'react';
import styled from 'styled-components';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { LoadingIcon } from '@australiangreens/components';
import LocationSources from '~/helpers/constants/LocationSources';
import { Location } from '~/helpers/types/Location';
import { connect, actions } from '~/store';

/** Magic CSS that styles a pseudo-select element. */
const AutocompleteList = styled.div`
  background-color: #fff;
  position: absolute!important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden
  padding: 5px;
  // margin-left: auto;
  // margin-right: auto;
  max-width: 100%;
`;

/** Magic CSS that styles an item in a pseudo-select element. */
const AutocompleteItem = styled.div`
  cursor: default;
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #e6e6e6;
  font-size: 12px;
  &:hover {
    background-color: #fafafa;
  }
  width: 100%;
`;

type AddressLookupProps = {
  /** The coordinates of the default location of this election.
   * Used by the Google Places API to order address suggestions
   * and restrict suggested addresses to within a specific radius.
   */
  defaultLocation: Location;
};

type AddressLookupState = {
  /** Are we waiting on results from the Google Places API? */
  loadingElectorate: boolean;
  /** The current value of the address input field. */
  address: string;
  /** Have we found an electorate for the address?
   * If true but this component is still rendering,
   * that indicates there's no candidate for this electorate.
   */
  loadedElectorate: boolean;
};

/**
 * Component that provides functionality for the user to have their electorate looked up
 * by entering their residential address.
 * Uses the 'react-places-autocomplete' library which wraps around the Google Places API.
 */
export class AddressLookup extends Component<AddressLookupProps, AddressLookupState> {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      loadingElectorate: false,
      loadedElectorate: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  /** Function called when the value of the address input field is changed. */
  handleChange(address): void {
    this.setState({ address });
  }

  /** Function called when the user selects an address from the autocomplete prompt. */
  handleSelect(address): void {
    this.setState({ loadingElectorate: true });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        actions.guessElectorate(latLng.lat, latLng.lng, 0, LocationSources.ADDRESS);
        this.setState({ address: '', loadingElectorate: false, loadedElectorate: true });
      });
  }

  render(): ReactElement {
    const { defaultLocation } = this.props;
    const { address, loadingElectorate, loadedElectorate } = this.state;
    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={1000}
        searchOptions={{
          location: new google.maps.LatLng(defaultLocation.lat, defaultLocation.lng),
          radius: 20000,
          types: ['geocode'],
          componentRestrictions: { country: 'AU' },
        }}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <div style={{ maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
            <input style={{ width: '100%' }} {...getInputProps()} />
            <AutocompleteList>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <AutocompleteItem {...getSuggestionItemProps(suggestion)}>
                  <span>
                    <i className="fa fa-map-marker" aria-hidden="true" /> <b>{suggestion.formattedSuggestion.mainText}</b>, {suggestion.formattedSuggestion.secondaryText}
                  </span>
                </AutocompleteItem>
              ))}
            </AutocompleteList>
            {loadingElectorate && <div style={{ textAlign: 'center' }}><LoadingIcon /></div>}
            {loadedElectorate && false && <b>We do not appear to have a candidate in your electorate.</b>}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default connect((state) => ({ defaultLocation: state.election.defaultLocation }))(AddressLookup);
