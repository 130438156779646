import React, { useEffect } from 'react';
import Config from '~/config';
import { actions, connect } from '~/store';

/**
 * Wrapper for the entire app which redirects if auth is enabled and no token is present.
 */
const AuthWrapper: React.SFC<{authToken: string}> = ({ children, authToken }) => {
  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.replace('#', ''));
    const hashToken = hashParams.get('token');
    if (Config.requireAuth) {
      if (!authToken) {
        if (hashToken) {
          actions.setAuthToken(hashToken);
          window.history.replaceState(null, '', window.location.href.replace(`#token=${hashToken}`, ''));
        } else {
          window.location = Config.authUrl;
        }
      }
    }
  });

  return (
    <>
      {!Config.requireAuth || (Config.requireAuth && authToken) ? children : <p>Authorising...</p>}
    </>
  );
};

export default connect((state) => ({
  authToken: state.authToken,
}))(AuthWrapper);
