import React from 'react';
import { chunk } from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import PopTile from '../../atoms/PopTile/PopTile';

type PopGridProps = {
  tiles: any[];
  rowSize?: number;
};

const PopGrid: React.SFC<PopGridProps> = ({ tiles, rowSize }) => {
  const renderRow = (items, rowIndex) => (
    <Row noGutters key={rowIndex}>
      {items.map((tile) => (
        <Col md={12 / rowSize} key={tile.alt}>
          <PopTile {...tile} />
        </Col>
      ))}
    </Row>
  );
  const rows = chunk(tiles, rowSize);
  return <Container>{rows.map(renderRow)}</Container>;
};

PopGrid.defaultProps = {
  rowSize: 3,
};

export default PopGrid;
