import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

/** Bullet points for the 'why vote green' content */
const bulletPoints = [
  {
    text: 'Create 180,000 new jobs in renewables in a planned transition away from coal towards 100% renewable energy',
    link: 'https://greens.org.au/platform/renewables',
  },
  {
    text: 'Give every child a place in childcare and bring back free TAFE and Uni',
    link: 'https://greens.org.au/platform/services#education',
  },
  {
    text: 'Build 500,000 sustainable, affordable community homes',
    link: 'https://greens.org.au/platform/homes',
  },
  {
    text: 'Cover dental under Medicare and fund world-class hospitals',
    link: 'https://greens.org.au/platform/services#healthcare',
  },
  {
    text: 'Ban the corporate donations that corrupt Australian politics',
    link: 'https://greens.org.au/platform/democracy',
  },
];

/** A table cell. */
const Cell = styled.td`
  vertical-align: text-top;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0px;
`;

/** A bullet point within a table cell. */
const Point = styled.h4`
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
`;

/** A styled link. */
const Link = styled.a`
  font-weight: bold;
`;

/**
 * Generates a table from provided bullet points.
 */
export const generatePoints = (Points: any[]): ReactNode => (
  <table>
    <tbody>
      {Points.map((bul) => (
        <tr>
          <Cell>▲</Cell>
          <Cell>
            <Point>
              {bul.text} ·{' '}
              <Link href={bul.link} style={{ fontWeight: 'bold' }}>
                More »
              </Link>
            </Point>
          </Cell>
        </tr>
      ))}
    </tbody>
  </table>
);

/**
 * Component for the 2019 Federal election "Why Vote Green" section.
 */
export const Fed19WVG = () => (
  <Container>
    <>
      <h1>Our vision</h1>
      <p>
        Politics should give you something to believe in. That’s why the Greens have bold, evidence-based plans to
        build a future for all of us, not just the wealthy few.
      </p>
      <p>
        By ending the $11 billion a year tax avoidance industry and making big corporations pay their fair share, we
        can fund the things that benefit everyone.
      </p>
    </>
    <>
      <h1>Our plan</h1>
      {generatePoints(bulletPoints)}
    </>
  </Container>
);

export default Fed19WVG;
