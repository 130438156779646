import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Component for the 2024 QLD LGA "Why Vote Green" section.
 */
export const BCC24WVG = () => (
  <Container>
    <div className="container pt-1">
      <p>The big end of town is making record profits, while thousands are struggling to pay rents and mortgages. The Greens want to challenge the treatment of housing as a for-profit commodity, and make developers pay their fair share towards the cost of public infrastructure and facilities.</p>
      <p>The Qld local government elections is coming up on Saturday, 16 March, 2024.</p>
      <p>There&apos;s a lot more to local council than just fixing potholes and issuing parking fines. Right across Qld we&apos;ve got plans to make housing cheaper, fix public transport, improve road safety, and stop big developers from manipulating the planning system.</p>
    </div>
  </Container>
);

export default BCC24WVG;
