/** Generally speaking, the only types of elections. */
/* TODO: Turn into a Type or merge with existing Type. */
export const LOCAL = 'Local';
export const STATE = 'State';
export const FEDERAL = 'Federal';

export default [
  LOCAL,
  STATE,
  FEDERAL,
];
