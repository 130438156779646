import React, { Component } from 'react';
import styled from 'styled-components';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';

/**
 * Material UI Tabs container styled with Greens background colour and flex.
 */
const Tabs = styled(MaterialTabs).attrs({ className: 'u-bg-green-fun' })`
  flex-grow: 1;
`;

/**
 * Material UI Tab element with Greens style tweaks.
 */
const Tab = styled(MaterialTab)`
  color: white !important;
  font-weight: bold !important;
  line-height: 1.1rem;
  padding: 0.5rem 0;
`;

type AppTabsProps = {
  /**
   * Function called when user tries to change the current section.
   * Calling this should result in the children changing as section re-renders.
   */
  changeSection: (...args: any[]) => any;
  /** The name of the current section, used to change which tab is showing as selected. */
  currentSection: string;
};

/**
 * Mobile UI element which allows users to change between app sections using tabs.
 * Material UI reference: https://material-ui.com/components/tabs/
 * Material design reference: https://material.io/components/tabs
 */
class AppTabs extends Component<AppTabsProps, {}> {
  handleSectionChange = (_: any, section: string) => {
    const { changeSection } = this.props;
    changeSection(section);
  };

  render() {
    const { children, currentSection } = this.props;
    const sections = [
      {
        key: 'how',
        title: 'How to Vote',
      },
      {
        key: 'where',
        title: 'Where to Vote',
      },
      {
        key: 'why',
        title: 'Why Vote Greens',
      },
    ];
    return (
      <div style={{ paddingBottom: '10px' }}>
        <Tabs variant="fullWidth" value={currentSection} onChange={this.handleSectionChange} TabIndicatorProps={{ style: { backgroundColor: 'white' } }}>
          {sections.map((s) => <Tab label={s.title} key={s.key} value={s.key} />)}
        </Tabs>
        {children}
      </div>
    );
  }
}

export default AppTabs;
