import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Component for the 2024 TAS "Why Vote Green" section.
 */
export const TAS24WVG = () => (
  <Container>
    <div className="container pt-1">
      <p>After a decade of the Liberals in government, Tasmania&apos;s in a bit of a mess. Instead of tackling the health and housing crisis, Labor and Liberal are backing a billion dollar stadium.</p>
      <p>Something has to change. This election is an exciting opportunity to turn things around. To make that happen we need a new government, with the Greens in balance of power.</p>
      <p>The Greens will:</p>
      <ul>
        <li>Build public and affordable homes and control rising rents</li>
        <li>Tackle the health crisis and get people out of ambulances and into hospital beds</li>
        <li>Protect our unique environment and end native forest logging once and for all.</li>
      </ul>
    </div>
  </Container>
);

export default TAS24WVG;
