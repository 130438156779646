import React from 'react';
import { Container } from 'react-bootstrap';
import { PopGrid } from '@australiangreens/components';
// import ElectionIssues from '~/helpers/constants/ElectionIssues';
// <PopGrid tiles={ElectionIssues.wa21} rowSize={3} />

/**
 * Component for the 2021 WA state election "Why Vote Green" section.
 */
export const Wa21WVG = () => (
  <Container>
    <>
      <p>This election, our community wants strong action on climate change, funding for the services we need and to remove corporate influence out of politics.</p>
      <p>With more Greens MPs in Parliament, we can ensure that WA Labor transitions to 100% renewable energy and stops pandering to the gas and coal industries, instead turning WA into a world leading renewable energy exporter.</p>
      <p>&nbsp;</p>
      <p>See our full platform at: <a href="https://greens.org.au/wa/platform">greens.org.au/wa/platform</a>.</p>
    </>

  </Container>
);

export default Wa21WVG;
