import React from 'react';

type FooterProps = {
  copyright: string;
  links?: any[];
  theme?: 'fed' | 'qld';
};

const Footer: React.SFC<FooterProps> = ({
  copyright, children, links, theme,
}) => {
  if (theme && theme === 'qld') {
    return (
      <footer className="footer">
        <div className="container-fluid container-padded">
          <div className="row">
            <div className="col-sm-2 col-lg">
              {children}
            </div>
            <div className="col-sm-2 col-lg">
              <h5>
              Links
              </h5>
              <ul className="list-unstyled">
                {links.map((l) => (
                  <li>
                    <a href={l.url}>
                      {l.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row authorised-by pb-4">
            <div className="col-sm">
              <small>
                <div className="block block-block-content block-block-content9e07a3f3-0874-495b-9db8-c4d2337cea3d">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>{copyright}</p>
                  </div>
                </div>
              </small>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer className="crm-page-footer" role="contentinfo">
      <div className="crm-page-footer__inner">
        <div className="container">
          <div className="crm-page-footer-content">
            <div className="greens-contribute-footer__wrapper">{children}</div>
          </div>
          <div className="page-footer-menu">
            <ul className="page-footer-menu__list">
              {links.map((l, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index} className="page-footer-menu__item">
                  <a href={l.url} className=" page-footer-menu__link">
                    {l.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="crm-page-footer-copyright">
            <p>{copyright}</p>
            <p style={{ fontWeight: 300 }}>The Greens acknowledge the Traditional Owners of country throughout Australia and recognise their continuing connection to land, waters and culture. We acknowledge that these lands were stolen and sovereignty was never ceded. We pay our respects to Elders past, present and emerging.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  children: null,
  links: [],
};

export default Footer;
