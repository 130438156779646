import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { DISTRICT } from '~/helpers/constants/ElectorateTypes';
import { Config } from '~/helpers/types/Config';
import { Instructions } from '~/helpers/types/Instructions';

// const Heading = styled.div`
//   text-align: center;
//   font-size: 16px;
//   text-transform: uppercase;
//   margin-bottom: 10px;
// `;
//
// const House = styled.span`
//   font-weight: bold;
// `;


type BallotHeaderProps = {
  /** The Greens candidate on this ballot.
   * Only used to check what type of electorate this ballot is for.
   */
  candidate: Instructions;
  /** The code for this election.
   * Using this is tech debt that must be addressed.
   */
  config: Config;
};

/**
 * The header at the top of a ballot.
 */
const BallotHeader: React.SFC<BallotHeaderProps> = ({ candidate, config }) => {
  const InstructionBox = styled.div.attrs({ className: config.election.code === 'fed19' && candidate.electorateType !== DISTRICT ? 'u-bg-green-fun' : 'u-bg-white' })`
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    margin-bottom: 25px;
    ${config.election.code === 'fed19' && candidate.electorateType !== DISTRICT && 'color: white;'} ${config.election.code === 'fed19'
    && candidate.electorateType !== DISTRICT
    && 'font-weight: bold;'}
    padding: 5px;
    font-size: ${config.election.code === 'fed19' && candidate.electorateType !== DISTRICT ? '20px' : '15px'};
  `;

  if (candidate.electorateType === DISTRICT) {
    return (
      <Container>
        <InstructionBox>
          {config.ballot.instructions.lower(candidate)}
        </InstructionBox>
      </Container>
    );
  }
  return (
    <Container>
      <InstructionBox>
        {config.ballot.instructions.upper(candidate)}
      </InstructionBox>
    </Container>
  );
};

export default BallotHeader;
