/* Temporarily disable ESLint complaining about booth_address until
  there's a proper transformation/normalisation helper */
/* eslint-disable camelcase, @typescript-eslint/camelcase, react/jsx-no-target-blank */
import React, { Fragment, ReactElement } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { filter, sortBy, reverse } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import SausageIcon from './DemocracySausageIcons/SausageIcon';
import BaconAndEggsIcon from './DemocracySausageIcons/BaconAndEggsIcon';
import CakeIcon from './DemocracySausageIcons/CakeIcon';
import CoffeeIcon from './DemocracySausageIcons/CoffeeIcon';
import HalalIcon from './DemocracySausageIcons/HalalIcon';
import VegoIcon from './DemocracySausageIcons/VegoIcon';
import { DemocracySausageStall } from '~/helpers/types/DemocracySausageStall';

/** Styled heading for the booth info. */
const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
`;

/** CSS style for Democracy sausage icons. */
const demSausIcon = {
  verticalAlign: 'middle',
  marginRight: 3,
};

/* Many of these props come verbatim from Rocket. */
type BoothInfoProps = {
  /** The name of the booth (e.g. 'Coburg North'). */
  name: string;
  /** The name of the premises of the booth (e.g. 'Coburg Primary School'). */
  premises: string;
  /** The address of the booth premises. */
  booth_address: string;
  /**
   * The day this booth is open, represented by
   * the number of days before the election date.
   * If == 0, this is a polling day booth.
   * If > 0, this is a prepoll booth.
   * This is actually just a random non-zero number in case of a prepoll booth, as we squish all prepoll booths together.
   */
  prepoll: string;
  /** The days this booth is open, if prepoll. */
  days?: any[];
  /** The current number of days until the election. */
  daysUntilElection: number;
  /**
   * The Democracy Sausage stall, if:
   * A) DemSausage integration is enabled,
   * B) Booth has matching entry in Democracy Sausage API,
   * C) Matched entry has a stall registered on Democracy Sausage.
   */
  stall?: DemocracySausageStall;
  /**
   * The 'chance of sausage' from Democracy Sausage, if
   * A) DemSausage integration is enabled,
   * B) Booth has matching entry in Democracy Sausage API,
   * C) Matched entry does not yet have a stall registered on Democracy Sausage,
   * D) Democracy Sauage have matched this booth to previous elections to be able to calculate the chance.
   * Takes the form of a decimal number representing the chance from 0-1.
   */
  chance_of_sausage?: number;
  /**
   * The ID of the matched Democracy Sausage entry, if
   * A) DemSausage integration is enabled,
   * B) Booth has matching entry in Democracy Sausage API.
   */
  democracy_sausage_id?: number;
  /** The function to be called when the booth info dialog should be closed. */
  closeDialog: (...args: any[]) => any;
  /** Wheelchair accessibility info about the booth, if available. */
  wheelchair_access?: string;
  /** Is Democracy Sausage integration enabled for this election? */
  electionHasSausage: boolean;
};

enum PollingPlaceChanceOfSausage {
  NO_IDEA = 0,
  UNLIKELY = 1,
  MIXED = 2,
  FAIR = 3,
  STRONG = 4,
}

const BoothInfo: React.SFC<BoothInfoProps> = ({
  name, premises, booth_address, prepoll, days, daysUntilElection, stall, chance_of_sausage, democracy_sausage_id, closeDialog, wheelchair_access, electionHasSausage,
}) => {
  /**
   * Helper to try and give a valid link to the Google Maps directions
   * from user's current location (as detemrmined by Google Maps)
   * to the booth.
   * Excludes the 'premises'field if the booth is prepoll,
   * as this is usually not a real premises name (e.g. "Coburg Prepolling Centre").
   * */
  const directionsURL = prepoll === '0'
    ? `https://www.google.com/maps/dir/Current+Location/${encodeURIComponent(`${premises}, ${booth_address}`)}`
    : `https://www.google.com/maps/dir/Current+Location/${encodeURIComponent(`${booth_address}`)}`;


  /**
   * Helper to turn a demical chance of sausage into a simple string representation.
   * Returns 'unknown' if chance is not available.
   * The chance -> label mapping comes from Democracy Sausage.
   */
  const chanceOfSausage = (chance) => {
    switch (chance) {
      case PollingPlaceChanceOfSausage.STRONG:
        return 'STRONG';
      case PollingPlaceChanceOfSausage.FAIR:
        return 'FAIR';
      case PollingPlaceChanceOfSausage.MIXED:
        return 'MIXED';
      case PollingPlaceChanceOfSausage.UNLIKELY:
        return 'UNLIKELY';
      case PollingPlaceChanceOfSausage.NO_IDEA:
        return 'UNKNOWN';
      default:
        return 'UNKNOWN';
    }
  };

  /**
   * Helper to render information about prepoll booth opening days
   * relative to the current day.
   * Largely copied from the old HTV app, needs to be cleaned up.
   */
  const renderDateInfo = (boothDays, dUE): ReactElement => {
    const remainingDays = reverse(sortBy(filter(boothDays, (day) => day.daysFromElection <= dUE), (day) => parseInt(day.daysFromElection, 10)));
    let text;
    // TODO: This breaks if there's no remaining open days between now and election day
    if (parseInt(remainingDays[0].daysFromElection, 10) === dUE) {
      text = (
        <p>
          Open today!<br /> {remainingDays[0].times}
        </p>
      );
    } else {
      const nextDay = remainingDays[0];
      const theDate = new Date();
      const dayDiff = dUE - nextDay.daysFromElection;
      theDate.setDate(theDate.getDate() + dayDiff);
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dateString = `${daysOfWeek[theDate.getDay()]} ${months[theDate.getMonth()]} ${theDate.getDate()}`;
      text = (
        <p>
          Open next:<br />
          {dateString} - {nextDay.times}
        </p>
      );
    }
    return (
      <>
        <Heading>Dates</Heading>
        {text}
      </>
    );
  };

  /**
   * Helper to return a URL to Democracy Sausage.
   * If the ID of the Democracy Sausage polling place is available, link directly there.
   * If not, link to the homepage.
   * Includes a referrer tag to help with Democracy Sausage's analytics.
   * TODO: Cleanup hardcoded election slug in here.
   */
  const sausageUrl = 'https://democracysausage.org?referrer=GreensHowToVote';
  // const sausageUrl = democracy_sausage_id ? `https://democracysausage.org/federal_election_2019/polling_places/${democracy_sausage_id}?referrer=GreensHowToVote` : 'https://democracysausage.org?referrer=GreensHowToVote';

  return (
    <Dialog open onClose={closeDialog} maxWidth="xs" PaperProps={{ style: { margin: '16px', maxHeight: 'calc(100% - 48px)' } }} fullWidth>
      <div className="u-bg-green-fun">
        <h1
          style={{
            color: 'white',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {name}
        </h1>
      </div>
      <Container style={{ padding: '10px' }}>
        <Heading>Location</Heading>
        <ul className="fa-ul" style={{ marginLeft: '26px', marginTop: '5px' }}>
          <li>
            <i className="fa-li fa fa-home" />
            <b>{premises}</b>
          </li>
          <li>
            <i className="fa-li fa fa-map-marker" />
            <a href={directionsURL} target="_blank">
              {booth_address}
            </a>
          </li>
          {wheelchair_access && (
            <>
              <li>
                <i className="fa-li fa fa-wheelchair" />Wheelchair Access: {wheelchair_access}
              </li>
            </>
          )}
        </ul>
        {prepoll !== '0' && renderDateInfo(days, daysUntilElection)}
        {prepoll === '0' && electionHasSausage && <Heading style={{ marginTop: 0 }}>Food &amp; Drink</Heading>}
        {prepoll === '0'
          && electionHasSausage && (
            <p style={{ marginTop: -5, marginBottom: 5 }}>
              <a href={sausageUrl} target="_blank">
                <small>by DemocracySausage.org</small>
              </a>
            </p>
        )}

        {electionHasSausage
          && stall && (
          <>
            <p>
              {stall.noms.bbq && (
                <>
                  <SausageIcon width="20px" height="20px" style={demSausIcon} /> Sausage sizzle<br />
                </>
              )}
              {stall.noms.cake && (
                <>
                  <CakeIcon width="20px" height="20px" style={demSausIcon} /> Cake stand<br />
                </>
              )}
              {stall.noms.vego && (
                <>
                  <VegoIcon width="20px" height="20px" style={demSausIcon} /> Vegetarian options<br />
                </>
              )}
              {stall.noms.halal && (
                <>
                  <HalalIcon width="20px" height="20px" style={demSausIcon} /> Halal options<br />
                </>
              )}
              {stall.noms.coffee && (
                <>
                  <CoffeeIcon width="20px" height="20px" style={demSausIcon} /> Coffee<br />
                </>
              )}
              {stall.noms.bacon_and_eggs && (
                <>
                  <BaconAndEggsIcon width="20px" height="20px" style={demSausIcon} /> Bacon and eggs<br />
                </>
              )}
            </p>
          </>
        )}
        {prepoll === '0'
          && !stall
          && electionHasSausage && (
          <>
            <p>
                No info yet. The chance of sausage is <b>{chanceOfSausage(chance_of_sausage)}</b>.
            </p>
          </>
        )}
      </Container>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={closeDialog}
        color="primary"
        style={{
          width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0, margin: 0,
        }}
      >
        Close
      </button>
    </Dialog>
  );
};

BoothInfo.defaultProps = {
  days: undefined,
  stall: undefined,
  chance_of_sausage: undefined,
  democracy_sausage_id: undefined,
  wheelchair_access: undefined,
};

export default BoothInfo;
