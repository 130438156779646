const endpoints = {
  electorateLookup: '/electorate',
  preferences: '/preferences',
  candidate: '/candidate',
  booths: '/booths',
  packet: '/packet',
};

const electionCode = process.env.HTV_ELECTION ? process.env.HTV_ELECTION.toUpperCase() : null;

const configs = {
  local: {
    electionCode,
    env: 'local',
    hotJar: {
      id: 1221016,
    },
    requireAuth: false,
    authUrl: 'https://members.greens.org.au/bounce/fed22htvlocal',
  },
  dev: {
    electionCode,
    env: 'dev',
    theme: 'qld',
    hotJar: {
      id: 1221016, // https://nsw19.htv.dev.greens.systems/
    },
    requireAuth: false,
    authUrl: 'https://members.greens.org.au/bounce/fed22htv',
  },
  prod: {
    electionCode,
    env: 'prod',
    hotJar: {
      id: 1214019, // https://vote.greens.org.au
    },
    requireAuth: false,
  },
};

const activeConfig = configs[process.env.HTV_ENV];

export default {
  googleMapsKey: 'AIzaSyAaxUEa0pbNYyTFnx3-8LicqY8903p8SuA',
  mapboxApiKey: 'pk.eyJ1IjoiaW5lZmZ5YmxlIiwiYSI6ImNqdW90OTJkYTBqeW00ZXBpOXg0cmZzdHEifQ.G5fzVIE-uV878X9eKZJp5A',
  googleAnalyticsCode: 'UA-6875953-1',
  facebookPixelCode: '218184722269912',
  sentryDsn: 'https://43861973d92643538a4531cf7186201a@sentry.io/1315345',
  hotJar: {
    snippetVersion: 6,
  },
  ...activeConfig,
};
