/**
 * Constants with the relevant 'election issues' to be displayed in the WhyVoteGreen section.
 * Somewhat deprecated, hasn't been used in recent elections, as we needed more flexibility.
 */
// vic18
// import TrainIcon from '../../assets/images/TrainIcon.png';
// import HousingIcon from '../../assets/images/HousingIcon.png';
// import SolarIcon from '../../assets/images/SolarIcon.png';
// import ForestsIcon from '../../assets/images/ForestsIcon.png';

// nsw19
// import HomesForEveryone from '../../assets/images/homes-for-everyone.jpg';
// import ClimateAction from '../../assets/images/climate-action.jpg';
// import Environment from '../../assets/images/environment.jpg';
// import PeopleNotProfit from '../../assets/images/people-not-profit.jpg';
// import PoliticalCorruption from '../../assets/images/political-corruption.jpg';
// import StrongerCommunities from '../../assets/images/stronger-communities.jpg';

// wa21

// import Wa21WVGClimate from '../../assets/images/wa21wvg-climate.jpg';
// import Wa21WVGCommunity from '../../assets/images/wa21wvg-community.jpg';
// import Wa21WVGDemocracy from '../../assets/images/wa21wvg-democracy.jpg';
// import Wa21WVGEssentials from '../../assets/images/wa21wvg-essentials.jpg';
// import Wa21WVGFirstNations from '../../assets/images/wa21wvg-firstnations.jpg';
// import Wa21WVGJobs from '../../assets/images/wa21wvg-jobs.jpg';

// sa22

// import Sa22WVGHealth from '../../assets/images/sa22wvg-health.png';
// import Sa22WVGClimate from '../../assets/images/sa22wvg-climate.png';
// import Sa22WVGHousing from '../../assets/images/sa22wvg-housing.png';
// import Sa22WVGTransport from '../../assets/images/sa22wvg-transport.png';
// import Sa22WVGServices from '../../assets/images/sa22wvg-services.png';
// import Sa22WVGDevelopers from '../../assets/images/sa22wvg-developers.png';

import Vic22WVGRenewables from '../../assets/images/htv_vic22_renewables.png';
import Vic22WVGRent from '../../assets/images/htv_vic22_rentcaps.png';
import Vic22WVGIntegrity from '../../assets/images/htv_vic22_integrity.png';

import Nsw23WVGCoal from '../../assets/images/nsw23_endcoal.png';
import Nsw23WVGPokies from '../../assets/images/nsw23_pokies.png';
import Nsw23WVGRent from '../../assets/images/nsw23_rents.png';

const wa21ElectionSite = 'https://greens.org.au/wa/platform';

// const wa21 = [
//   {
//     alt: 'Essentials services for all',
//     image: Wa21WVGEssentials,
//     url: `${wa21ElectionSite}/essentials`,
//   },
//   {
//     alt: 'Jobs and economic recovery',
//     image: Wa21WVGJobs,
//     url: `${wa21ElectionSite}/economy`,
//   },
//   {
//     alt: 'Our Climate Crisis plan',
//     image: Wa21WVGClimate,
//     url: `${wa21ElectionSite}/climate`,
//   },
//   {
//     alt: 'Our plan to clean up politics',
//     image: Wa21WVGDemocracy,
//     url: `${wa21ElectionSite}/democracy`,
//   },
//   {
//     alt: 'Caring for community',
//     image: Wa21WVGCommunity,
//     url: `${wa21ElectionSite}/caring`,
//   },
//   {
//     alt: 'First Nations justice, heritage and health',
//     image: Wa21WVGFirstNations,
//     url: `${wa21ElectionSite}/firstnations`,
//   },
// ];

// const fed22 = [
//  {
//    alt: 'Truth & Treaty',
//    image: Fed22WVGTreaty,
//    url: 'https://greens.org.au/platform/first-nations',
//  },
//  {
//    alt: 'Beyond Coal & Gas',
//    image: Fed22WVGCoal,
//    url: 'https://greens.org.au/climate-crisis',
//  },
//  {
//    alt: 'Dental & Mental Health into Medicare',
//    image: Fed22WVGMedicare,
//    url: 'https://greens.org.au/cost-of-living',
//  },
// ];

const vic22 = [
  {
    alt: 'Replace coal & gas with 100% renewables',
    image: Vic22WVGRenewables,
    url: 'https://greens.org.au/vic/platform/climate',
  },
  {
    alt: 'Build more affordable homes & cap rent increases',
    image: Vic22WVGRent,
    url: 'https://greens.org.au/vic/platform/housing',
  },
  {
    alt: 'Restore integrity to politics',
    image: Vic22WVGIntegrity,
    url: 'https://greens.org.au/vic/platform/integrity',
  },
];
const nsw23 = [
  {
    alt: 'End coal & gas by 2030',
    image: Nsw23WVGCoal,
    url: 'https://greens.org.au/nsw/climatechange2023',
  },
  {
    alt: 'Freeze & control rents, & ban unfair evictions',
    image: Nsw23WVGRent,
    url: 'https://greens.org.au/nsw/makehousingaffordable2023',
  },
  {
    alt: 'Pull the pin on pokies',
    image: Nsw23WVGPokies,
    url: 'https://greens.org.au/sites/default/files/2022-12/INITIATIVE_PULL_THE_PIN_ON_POKIES-1.pdf',
  },
];
export default {
  // fed22,
  // vic22,
  nsw23,
};
