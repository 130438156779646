import React, { ReactElement } from 'react';
import { PageSection } from '@australiangreens/components';
import HowToVote from '~/components/sections/HowToVote/HowToVote';
import WhereToVote from '~/components/sections/WhereToVote/WhereToVote';
import WhyVoteGreen from '~/components/sections/WhyVoteGreen/WhyVoteGreen';

/** The sections of the How To Vote app using PageSection.
 *  Only displayed on desktop.
 */
const AppSections = (): ReactElement => (
  <div style={{ paddingBottom: '15px' }}>
    <HowToVote />
    <PageSection
      title="Where to vote"
      intro="Find your nearest polling place"
      background="full"
    >
      <WhereToVote />
    </PageSection>
    <PageSection
      title="Why vote Greens"
      background="full"
    >
      <WhyVoteGreen />
    </PageSection>
  </div>
);

export default AppSections;
