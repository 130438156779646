import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { actions } from '~/store';
import { SelectedElectorates } from '~/helpers/types/Electorate';

/** Greens styled Material UI AppBar. */
const Bar = styled(AppBar).attrs({ className: 'u-bg-green-camarone' })`
  background-color: #005f27 !important;
  border-bottom: 2px solid white;
  min-height: 48px;
`;

/** Styled Material UI Typography wrapper for text in the bar. */
const Text = styled(Typography)`
  font-weight: bold !important;
  margin-left: 10px !important;
  margin-top: auto;
  margin-bottom: auto;
`;

/** Styled back icon, used to indicate that the user can tap the bar to change electorate. */
const Icon = styled.i.attrs({ className: 'fa fa-angle-left fa-2x' })`
  vertical-align: -6px;
`;

/** Styled span containing the lower house electorate name, if any. */
const Electorate = styled.span`
  padding-left: 10px;
  text-transform: uppercase;
`;

type HeaderBarProps = {
  /** The currently selected electorates, if any. */
  electorates?: SelectedElectorates;
};

/**
 * The header that appears at the top of the Mobile HTV app.
 * Wrapped and styled version of Material UI AppBar.
 */
const HeaderBar: React.SFC<HeaderBarProps> = (props) => {
  const { electorates } = props;
  return (
    <Bar position="static" onClick={() => actions.clearElectorates()}>
      <Text variant="h6" color="inherit" noWrap>
        <Icon />
        <Electorate>{electorates ? electorates.lower.name : '...'}</Electorate>
      </Text>
    </Bar>
  );
};

HeaderBar.defaultProps = {
  electorates: null,
};

export default HeaderBar;
