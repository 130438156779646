import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Component for the 2024 QLD LGA "Why Vote Green" section.
 */
export const QLD24WVG = () => (
  <Container>
    <div className="container pt-1">
      <p align="center"><strong>A little more for regular Queenslanders, a little less for big corporations</strong></p>
      <p>Right across QLD we&apos;ve got plans to:</p>
      <ul>
        <li><strong>Fix the housing crisis</strong> by freezing rents for 2 years and capping increases on-going</li>
        <li><strong>Tackle climate change</strong> through investment in 100% public renewables and stopping new coal and gas mines</li>
        <li><strong>Fully fund education and healthcare</strong> with proper investment in schools, TAFE, hospitals and GP clinics</li>
        <li><strong>Make big corporations pay their fair share</strong> to fund a better life for all Queenslanders</li>
      </ul>
    </div>
  </Container>
);

export default QLD24WVG;
