/** Helper functions for generating & rendering ballot papers. */
/* eslint-disable import/prefer-default-export */
import { DISTRICT, REGION, STATE } from '~/helpers/constants/ElectorateTypes';
import { UpperBallotColours, LowerBallotColour } from '~/helpers/constants/BallotColours';
import { Config } from './types/Config';
import { Electorate, ElectorateType } from './types/Electorate';

/**
 * Helper function to determine the colour of a ballot paper.
 * Uses election-wide lower and upper ballot colour config if provided,
 * otherwise uses colours from the BallotColours constant.
 * TODO: Refactor to remove all election-specific logic and use config.
 */
export const getBallotColour = (electorate: Electorate, config: Config): string => config.ballot.getBallotColour(electorate);
