/** Helper functions for loading configuration. */
/* eslint-disable import/prefer-default-export */

import { Config } from '~/helpers/types/Config';
import { SelectedElectorates } from './types/Electorate';

/** Function that works out final configuration, by loading:
 * A) the base config (from config.js),
 * B) the election config (from constants/Elections.js),
 * C) and the config for the current electorates (if any are selected) (from constants/Electorates.js),
 * and merging them all together.
 */
export const getConfig = (
  baseConfig: Config, electionConfig: Config, electorateConfig: { [key: string]: Config }, currentElectorates?: SelectedElectorates,
): object => {
  let lower;
  let upper;
  if (currentElectorates?.lower) {
    lower = currentElectorates.lower.name;
  }
  if (currentElectorates?.upper) {
    upper = currentElectorates.upper.name;
  }
  let config: Config = {};
  config = Object.assign(config, baseConfig);
  config = Object.assign(config, electionConfig);
  if (upper && electorateConfig[upper]) {
    Object.assign(config, electorateConfig[upper]);
  }
  if (lower && electorateConfig[lower]) {
    Object.assign(config, electorateConfig[lower]);
  }
  return config;
};
