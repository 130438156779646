import React, { Component, ReactNode } from 'react';
import { captureException } from '@sentry/browser';
import { Alert } from 'react-bootstrap';

type ErrorBoundaryProps = {
  /** Children components of the React error boundary. */
  children: ReactNode;
}

type ErrorBoundaryState = {
  /** The error that has occurred. */
  error: Error;
};

/**
 * A component that implements React error boundary functionality.
 * This allows errors to occur within the boundary without blowing up the entire app.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error): void {
    captureException(error);
    this.setState({ error });
  }

  render(): ReactNode {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <Alert variant="danger">
          <Alert.Heading>An error occurred</Alert.Heading>
          <p>Please refresh and try again.</p>
          <p>
            If this issue persists, please contact <a href="mailto:digital@greens.org.au">digital@greens.org.au</a>.
          </p>
          <p>You can provide the following error code:</p>
          <code>${error.toString()}</code>.
        </Alert>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
