import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/browser';
import Config from '~/config';
import App from '~/components/pages/App/App';
import StoreWrapper from '~/components/wrappers/StoreWrapper/StoreWrapper';
import AuthWrapper from '../AuthWrapper/AuthWrapper';

/**
 * Wrapper for the entire app which adds analytics & error collection.
 */
const AppWrapper: React.SFC<{}> = () => {
  useEffect(() => {
    Sentry.init({ dsn: Config.sentryDsn, environment: Config.env });
    ReactPixel.init(Config.facebookPixelCode);
    hotjar.initialize(Config.hotJar.id, Config.hotJar.snippetVersion);
    // Register a Facebook Pixel Page View
    ReactPixel.pageView();
  });

  return (
    <StoreWrapper>
      <AuthWrapper>
        <App />
      </AuthWrapper>
    </StoreWrapper>
  );
};

export default AppWrapper;
