import React from 'react';
import { Container } from 'react-bootstrap';
import { PopGrid } from '@australiangreens/components';
import ElectionIssues from '~/helpers/constants/ElectionIssues';

/**
 * Component for the 2023 NSW State election "Why Vote Green" section.
 */
export const Nsw23WVG = () => (
  <Container>
    <div className="container pt-1">
      <p>Your vote can kick the Liberal-Nationals out, put the Greens in the balance of power, and push the next government to end  coal and gas by 2030, cut rents and ban unfair evictions, and pull the pin on pokies.</p>
      <PopGrid tiles={ElectionIssues.nsw23} rowSize={3} />
      <p>In NSW, Liberal, Nationals and Labor both give too much special treatment to big corporations and billionaires.</p>
      <p>Whether it’s gambling corporations, property developers and investors, big banks or coal and gas corporations - they’re getting huge handouts and making massive profits, while people are struggling to keep up.</p>
      <p>And it&apos;s driving the climate crisis and making the cost of living worse.</p>
      <p>We need to end the special treatment for the rich and big corporations in NSW and make government work for the people.</p>
      <p>We can stop new coal and gas, and make coal and gas corporations pay for the damage they have caused.</p>
      <p>We can stop giving prime public land to private developers, build affordable housing, and cut rents.</p>
      <p>We need to return public services to public hands, to reduce the cost of electricity, housing and transport.</p>
      <p>We can make a community lead Truth-telling and treaty a priority for NSW.</p>
      <p>This election, your vote is powerful.</p>
      <p>Vote 1 Greens</p>
      <p><a href="https://greens.org.au/nsw/platform">Read more about our plans &gt;&gt;</a>.</p>
    </div>
  </Container>
);

export default Nsw23WVG;
