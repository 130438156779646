import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { CallToAction } from '@australiangreens/components';
import withElection from '~/helpers/providers/withElection';
import { actions } from '~/store';
import BackgroundImage from './background.jpg';
import { Election } from '~/helpers/types/Election';

/**
 * A div that renders an image as a full-page background.
 */
const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover;
  background:
  linear-gradient(rgba(0,0,0,0.5),
  rgba(0,0,0,0.5)),
  url('${BackgroundImage}');
  vertical-align: center;
  display: grid;
  place-items: center center;
`;

/**
 * A styled heading for text shown after a 'keep me updated' signup is processed.
 */
const CompletionText = styled.h3`
  text-align: center;
  color: white;
`;

type ComingSoonProps = {
  /** The election details. */
  election: Election;
};

/**
 * A full-page section that shows if the election is marked as 'coming soon'.
 * Allows users to sign up for an email when the app is available.
 * Sends signups via the AGC packet API.
 */
const ComingSoon: React.SFC<ComingSoonProps> = ({ election: { name } }) => {
  const [isPosted, setAsPosted] = useState(false);
  const handleSubmit = ({
    target: {
      elements: {
        firstName: { value: firstName },
        lastName: { value: lastName },
        email: { value: email },
        postcode: { value: postcode },
      },
    },
  }) => {
    actions.postPacket(firstName, lastName, email, postcode);
    setAsPosted(true);
  };
  return (
    <Background>
      <Container>
        <CallToAction
          title="Coming Soon"
          intro={`Sign up and you'll be the first to know when our guide on how to vote in the ${name} is available.`}
          includeLastName
          buttonText="Keep me updated"
          modifierClass="call-to-action--primary"
          submitHandler={handleSubmit}
          completionText={
            isPosted && (
              <CompletionText>Thank you for signing up. We will email you when the guide is launched.</CompletionText>
            )
          }
        />
      </Container>
    </Background>
  );
};

export default withElection(ComingSoon);
