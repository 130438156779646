/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';

const InputField = ({ label, type, pattern }) => (
  <div className="form-group col-lg-3">
    <label className="sr-only" htmlFor={`${camelCase(label)}`}>
      {label}
    </label>
    <input required type={type} pattern={pattern} className="form-control" id={`${camelCase(label)}`} name={`${camelCase(label)}`} placeholder={label} />
  </div>
);

const CallToActionForm = ({ includeLastName, submitHandler, buttonText }) => (
  <form onSubmit={submitHandler}>
    <div className="form-row align-items-center justify-content-center">
      <InputField label="First name" />
      { includeLastName && <InputField label="Last name" /> }
      <InputField label="Email" type="email" />
      <InputField label="Postcode" pattern="[0-9]{4}" />
      <div className="form-group col-lg-auto text-center">
        <button type="submit" className="btn btn-primary btn-lg">{buttonText}</button>
      </div>
    </div>
  </form>
);

CallToActionForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  includeLastName: PropTypes.bool,
};

CallToActionForm.defaultProps = {
  buttonText: 'Join Us',
  includeLastName: false,
};

const CallToAction = ({
  modifierClass, title, intro, submitHandler, buttonText, includeLastName, completionText,
}) => (
  <div className={`call-to-action ${modifierClass}`}>
    <div className="call-to-action__inner">
      <h2 className="call-to-action__title">{title}</h2>
      <div className="call-to-action__text"><p>{intro}</p></div>
      <div className="call-to-action__action">
        { completionText
        || (
          <CallToActionForm
            submitHandler={(e) => { e.preventDefault(); submitHandler(e); }}
            buttonText={buttonText}
            includeLastName={includeLastName}
          />
        )}
      </div>
    </div>
    <div className="call-to-action__border" />
  </div>
);

CallToAction.propTypes = {
  modifierClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  includeLastName: PropTypes.bool,
  completionText: PropTypes.node,
};

CallToAction.defaultProps = {
  modifierClass: '',
  intro: '',
  buttonText: 'Join Us',
  includeLastName: false,
  completionText: null,
};

export default CallToAction;
