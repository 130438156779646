import React from 'react';
import { Container } from 'react-bootstrap';

/**
 * Component for the 2024 ACT "Why Vote Green" section
 */
export const ACT24WVG = () => (
  <Container>
    <div className="container pt-1">
      <ul>
        <li><strong>Build and buy 10,000 public homes over the next 10 years</strong> to clear the public housing waiting list within the next term, and house many more Canberrans who are struggling to pay market rents.</li>
        <li><strong>Restore the availability of bulk-billing GPs in Canberra</strong>by opening four government-run GP clinics that will supply doctors with all the support they need to focus on seeing patients, delivering 160,000 free appointments each year.</li>
        <li><strong>Make public transport in Canberra a true ‘turn up and go’ service</strong> that you don’t need to plan your day around, with every weekday bus running every 20 minutes or less, and every weekend bus every 30 minutes.</li>
        <li><strong>Reclaim the convenience of Canberra</strong> with new planning rules to protect nature and build quality homes close to transport, schools, jobs and the everyday services you need.</li>
        <li><strong>Deliver real climate leadership</strong> with a faster and fairer transition to net-zero that supports every Canberran, no matter their income, to share in the health, comfort and cost benefits of getting rid of fossil fuel gas.</li>
        <li><strong>Empower parents and give kids the best start in life</strong> with 18 hours free preschool per week for 3- and 4-year-olds, and support for educators who provide this essential service.</li>
      </ul>
    </div>
  </Container>
);

export default ACT24WVG;
