import React from 'react';
import styled from 'styled-components';
import { Electorate } from '~/helpers/types/Electorate';

/** Container for the coming soon text. */
const InnerBallot = styled.div`
  padding: 15px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  min-height: 250px;
`;

type BallotComingSoonProps = {
  electorate: any;
  candidateInfo: any;
}

/**
 * Shown in place of a ballot if preferences are not available for an electorate.
 * This is usually, but not always, because the preferences are not available *yet*.
 */
const BallotComingSoon: React.SFC<BallotComingSoonProps> = ({ candidateInfo, electorate }) => (
  <InnerBallot>
    <h2>{candidateInfo?.name}</h2>
    <h3>{candidateInfo?.tagline}</h3>
    <h3 style={{ color: '#F6871F' }}>How to Vote coming soon...</h3>
  </InnerBallot>
);

export default BallotComingSoon;
