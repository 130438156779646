import React, { Component, ReactElement } from 'react';
import ReactGA from 'react-ga';
import BoothMarker from '~/components/sections/WhereToVote/BoothMap/Booths/BoothMarker/BoothMarker';
import BoothInfo from '~/components/sections/WhereToVote/BoothMap/Booths/BoothInfo/BoothInfo';
import { Booth } from '~/helpers/types/Booth';

type BoothsProps = {
  /** Array of booths to be rendered as markers on the map. */
  booths: any[];
  /** The current number of days until the election. */
  daysUntilElection: number;
  /** Is this election enabled for Democracy Sausage integration? */
  electionHasSausage: boolean;
};

type BoothsState = {
  /** The booth currently 'open' (with a BoothInfo dialog showing). */
  openBooth?: Booth;
};

/**
 * Component that is responsible for rendering all the booth markers on the map.
 * Astracts the map itself from the markers, so that changes to which booths are showing
 * don't re-render the entire map.
*/
class Booths extends Component<BoothsProps, BoothsState> {
  constructor(props: BoothsProps) {
    super(props);
    this.state = {
      openBooth: undefined,
    };
    this.showInfo = this.showInfo.bind(this);
  }

  /**
   * Function called to change which booth should be 'open'.
   * Called with a booth ID to open the BoothInfo dialog for that booth,
   * closing any other that is already open.
   * Called with 'null' to stop showing the BoothInfo dialog for any booths.
   * Sets the openBooth state to the object of the booth, which is then passed
   * to the BoothInfo component.
   */
  showInfo(boothId?: string): void {
    const { booths } = this.props;
    if (boothId === null) {
      this.setState({ openBooth: undefined });
    }
    const booth = booths.find((b) => b.pollingbooth_id === boothId);
    this.setState({ openBooth: booth });
    if (booth) {
      ReactGA.event({
        category: 'Where To Vote',
        action: 'Viewed Booth Info',
        label: booth.name,
      });
    }
  }

  render(): ReactElement {
    const { openBooth } = this.state;
    const { booths, daysUntilElection, electionHasSausage } = this.props;
    return (
      <div>
        {booths.map((booth) => (
          <BoothMarker
            key={booth.pollingbooth_id}
            location={{ lat: parseFloat(booth.latitude), lng: parseFloat(booth.longitude) }}
            onClick={() => {
              this.showInfo(booth.pollingbooth_id);
            }}
            stall={booth.stall}
            electionHasSausage={electionHasSausage}
          />
        ))}
        {openBooth && (
          <BoothInfo
            {...openBooth}
            electionHasSausage={electionHasSausage}
            daysUntilElection={daysUntilElection}
            closeDialog={() => {
              this.showInfo(null);
            }}
          />
        )}
      </div>
    );
  }
}

export default Booths;
